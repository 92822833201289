* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #000;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
}
.drawer-open-icon {
  color: #fff;
}
.drawer-container {
  background-color: #111;
  height: 100%;
}
.drawer-content {
  color: #eee;
}
.drawer-content path{
  fill: #eee;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: #000;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.primary-button:hover {
  background-color: #333;
}
.nav-button {
  cursor: pointer;
  position: relative;
}
.nav-button::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform-origin: bottom right;
  transition: transform 0.15s ease-out;
}
.nav-button:hover::after {
  transform: scale(1);
  transform-origin: bottom left;
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
  padding-left: 10%;
  padding-right: 10%;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #e4e4e4;
  /* line-height: 5rem; */
  max-width: 600px;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #e4e4e4;
  margin: 1.5rem 0rem;
}
.about-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 750px;
  color: #e4e4e4;
  margin: 1.5rem 0rem;
}
.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem 2rem 0rem;
}
.contact-form-container {
  background-color: #222;
  max-width: 700px;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}
.contact-email-container {
  flex-direction: row;
  display: flex;
}
.upper-contact-text {
  font-size: clamp(0.9rem, 2.5vw, 1.3rem);
  max-width: 500px;
  color: #e4e4e4;
  margin: 1.5rem 0rem 0.5rem 0rem;
  padding-right: 1rem;
}
.lower-contact-text {
  font-size: clamp(0.9rem, 2.5vw, 1.3rem);
  max-width: 500px;
  color: #e4e4e4;
  margin: 0.5rem 0rem 1.5rem 0rem;
  padding-right: 1rem;
}
.contact-email {
  font-size: clamp(0.9rem, 2.5vw, 1.3rem);
  position: relative;
  max-width: 500px;
  color: #eca437;
  margin: 1.5rem 0rem 0.5rem 0rem;
  text-decoration: none;
}
.contact-email::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #eca437;
  transform-origin: bottom right;
  transition: transform 0.15s ease-out;
}
.contact-email:hover::after {
  transform: scale(1);
  transform-origin: bottom left;
}
.contact-icon {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: #eee;
}
.contact-icon:hover {
  color: #aaa;

}
.footer-container {
  padding-top: 8rem;
}
.footer-text {
  color: #666;
}
.work-section-wrapper {
  margin-top: 15rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 290px;
  min-height: 450px;
  background-color: #222;
  padding: 4rem 2rem 0rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #e4e4e4;
  margin: 1rem 2rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-details-container {
  text-align: center;
}
.work-section-info p {
  flex: 1;
  /* display: flex; */
  font-weight: 500;
}
.work-section-info ul {
  padding-top: 10px;
  display: inline-block;
  text-align: start;
}
.work-img-container {
  max-width: 80px;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    border-radius: 1rem !important;
  }
  .App {
    max-width: 95vw;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
}
